.landing {
    padding-top: 12vh;
    height: 100vh;
    background: radial-gradient(
        at bottom right,
        rgba(235, 198, 68, 0.2625) 0%,
        rgba(251, 239, 231, 0) 82.29%
    );
}

.welcome {
    width: 30rem;
    margin-left: 12vw;
    text-align: left;
}

.descriptor {
    font-family: "Rany", sans-serif;
    margin-top: 20px;
    font-size: 49px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #26467e;
}

.line {
    margin-top: 20px;
    background: #ebc644;
    border: 2px solid #ebc644;
    height: 0px;
}

.date {
    font-family: "Rany", sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #26467e;
    margin-top: 20px;
}

.join {
    width: 16rem;
    height: 4rem;
    text-align: center;
    padding-top: 1.25rem;
    margin-top: 1rem;
    background: #f6e4a1;
    border-radius: 30px;
    font-family: "Rany", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 22px;
    color: #26467e;
    transition: 0.4s background-color;
    z-index: 10;
}

.join:hover {
    cursor: pointer;
    background: #ebc644;
    transition: 0.4s background-color;
}

  .buttonContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

@keyframes island_desktop {
    0% {
        transform: translateY(-0.5rem);
    }
    100% {
        transform: translateY(0.5rem);
    }
}

.island {
    height: 100vh;
    position: relative;
    padding-top: 15vh;
    animation: island_desktop 5s infinite ease-in-out alternate;
}

.conduct {
    font-family: "Rany", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #26467e;
    margin-top: 12px;
}

.island > * {
    position: absolute;
    z-index: 0;
}

@keyframes sun_desktop {
    0% {
        transform: translateY(-24rem) translateX(-2rem);
    }
    100% {
        transform: translateY(-27rem) translateX(-2rem);
    }
}

.sun {
    z-index: -1;
    transform: translateY(-24rem) translateX(-2rem);
    animation: sun_desktop 3s infinite ease-in-out alternate;
}

@keyframes purple_desktop {
    0% {
        transform: translateY(-24rem) translateX(21rem);
    }
    100% {
        transform: translateY(-26rem) translateX(21rem);
    }
}

.purple {
    transform: translateY(-24rem) translateX(21rem);
    animation: purple_desktop 2s 1s infinite ease-in-out alternate;
}

@keyframes pink_desktop {
    0% {
        transform: translateY(-23rem) translateX(15rem);
    }
    100% {
        transform: translateY(-26rem) translateX(15rem);
    }
}

.pink {
    transform: translateY(-23rem) translateX(15rem);
    animation: pink_desktop 4s 2s infinite ease-in-out alternate;
}

.conduct a {
    transition: 0.4s color;
}

.conduct a:hover {
    transition: 0.4s color;
}

@media screen and (max-width: 1024px) {
    .landing {
        padding-top: 5vh;
        height: 145vh;
    }

    .welcome {
        width: 25rem;
        margin: auto;
        margin-top: 20px;
        text-align: center;
    }

    .descriptor {
        text-align: center;
    }

    .date {
        text-align: center;
    }

    .right {
        text-align: center;
    }

    .island {
        margin-top: 20vh;
    }

    .bg {
        width: 80vw;
        left: 10vw;
    }

    @keyframes sun_mobile {
        0% {
            transform: translateY(-50vw) translateX(-40vw);
        }
        100% {
            transform: translateY(-57vw) translateX(-40vw);
        }
    }

    .sun {
        width: 40vw;
        transform: translateY(-50vw) translateX(-40vw);
        animation: sun_mobile 3s infinite ease-in-out alternate;
        z-index: -1;
    }

    @keyframes purple_mobile {
        0% {
            transform: translateY(-62vw) translateX(10vw);
        }
        100% {
            transform: translateY(-70vw) translateX(10vw);
        }
    }

    .purple {
        width: 30vw;
        transform: translateY(-62vw) translateX(10vw);
        animation: purple_mobile 2.5s 1s infinite ease-in-out alternate;
    }

    @keyframes pink_mobile {
        0% {
            transform: translateY(-48vw);
        }
        100% {
            transform: translateY(-52vw);
        }
    }

    .pink {
        width: 20vw;
        transform: translateY(-48vw);
        animation: pink_mobile 3s 2.3s infinite ease-in-out alternate;
    }

    .join {
        width: 60vw;
        /* transform: translateY(1vw) translateX(1); */
    }
  
    .buttonContainer {
        position: absolute;
        left: 20vw;
        flex-direction: column;
    }

    .conduct {
        position: absolute;
        width: 80vw;
        text-align: center;
        left: 10vw;
        transform: translateY(20vh);
        z-index: 10;
    }
}
