/* @import url('http://fonts.cdnfonts.com/css/rany'); */

.about {
    margin-top: -2rem;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    max-width: 100%; /* added */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fbf9f7;
    padding: 100px 0px;
}

.about-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 85%;
    height: 95%;
}

@media only screen and (max-width: 768px) {
    .about-grid-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }
}

.about-text {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    max-width: 600px;
    z-index: 10;
}

@media only screen and (min-width: 768px) and (max-width: 1040px) {
    .about-text {
        max-width: 337px;
    }
}

@media only screen and (max-width: 768px) {
    .about-text {
    }
}

@media only screen and (max-width: 570px) {
    .about-text h3 {
        max-width: 333px;
    }
}

.about-butterfly-right {
    width: 100%;
    height: 100%;
    min-height: 250px;
    /* align-items:flex-start;
    display: flex;
    padding: 25px 70px; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.about-butterfly-left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .about-butterfly-left {
        width: 0%;
        height: 0px;
    }
}

.about-text h3 {
    font-family: "Rany";
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #26467e;
    z-index: 1;
    padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (max-width: 390px) {
    .about-text h3 {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.about-text .red-stripe {
    position: inherit;
    margin-top: -43px;
    height: 45px;
    width: 100%;
    background: #fbe6dc;
    overflow: hidden;
}

.about-text p {
    font-family: "Rany";
    font-style: normal;
    font-weight: 150;
    font-size: 20px;
    line-height: 30px;
    color: #26467e;
    margin-top: 16px;
    padding-left: 15px;
    padding-right: 15px;
}

.butterfly4 {
    width: 120px;
    height: 120px;
    left: 833px;
    top: 1287px;
}

.butterfly5 {
    transform: translateY(90px);
    width: 120px;
    height: 120px;
    left: 833px;
    top: 1287px;
}

.line-block {
    z-index: 0;
    transform: translateY(-200px);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    right: 0px;
    object-fit: fill;
    overflow: hidden;
}

.line-block .line1 {
    width: 2500px;
    z-index: -1;
    height: 70%;
    position: absolute;
    margin: auto;
    display: block;
    object-fit: fill;
    bottom: 0px;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -20%);
}

.line-block .line1 .path1 {
    animation: dash1 30s linear infinite;
}

.line-block .line2 {
    width: 2500px;
    z-index: -1;
    height: 80%;
    position: absolute;
    margin: auto;
    display: block;
    object-fit: fill;
    bottom: 0px;
    top: 0px;
    left: 50%;
    transform: translate(-45%, 0);
}

.line-block .line2 .path2 {
    animation: dash2 30s linear infinite;
}

@media only screen and (min-width: 768px) and (max-width: 1040px) {
    .line-block .line1 {
        transform: translate(-45%, 0);
    }

    .line-block .line2 {
        transform: translate(-45%, 10%);
    }
}

@media only screen and (max-width: 768px) {
    .line-block .line1 {
        display: none;
    }

    .line-block .line2 {
        height: 60%;
        transform: translate(-40%, -50%);
    }
}

@keyframes dash1 {
    to {
        stroke-dashoffset: -1000;
    }
}

@keyframes dash2 {
    to {
        stroke-dashoffset: 1000;
    }
}

@media only screen and (max-width: 768px) {
    .butterfly4 {
        transform: translateY(-40px) translateX(30vw);
        margin-top: auto;
        margin-bottom: auto;
    }

    .butterfly5 {
        transform: translateY(40px) translateX(-30vw);
        margin-top: auto;
        margin-bottom: auto;
        height: 80px;
        width: 80px;
    }
}

.butterfly6 {
    transform: translateY(20px);
    width: 120px;
    height: 120px;
    left: 833px;
    top: 1287px;
}
