.schedule{
    width: 100vw;
    height: 100%;
    /* min-height: 100vh; */
    max-width: 100%;  /* added */
    display: flex;
    flex-direction: column;
    background: #FBF9F7;
    padding: 30px 150px;
}

@media screen and (max-width: 1024px){
    .schedule {
        padding: 30px 20px;
    }
  }

.schedule h2{
    font-family: 'Rany';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: 0.05em;
    padding-bottom: 25px;
    color: #26467E;

}

.sched {
  display: flex;
  justify-items: center;
}

.sched-column {
  float: left;
  width: 40%;
}

@media screen and (max-width: 1024px){
  .sched {
    flex-direction: column;
    align-items: center;
  }

  .entry {
    flex-direction: row;
  }

  .day{
    width: auto;
  }

  .time-place {
    width: 180px;
    font-size: 20.5px;
  }

  .sched-time {
    width: 120px;
  }
}
  
  /* Clear floats after the columns */
  .sched-row:after {
    content: "";
    display: table;
    clear: both;
  }

  .sched-date {
    font-family: "Rany", sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #26467e;
    margin-top: 20px;
    margin-bottom: 20px;
}
.time-place {
    font-family: "Rany";
    font-style: normal;
    font-weight: 300;
    font-size: 22.5px;
    line-height: 30px;
    color: #26467e;
    padding-bottom: 15px;
}