.navbar {
    /* position: relative; */
    /* position: absolute; */
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 75px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: top 150ms linear;

    /* overflow: hidden; */
}

.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 75px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    transition: top 150ms linear;

    /* overflow: hidden; */
}

#navbar-title {
    font-family: "Rany", sans-serif;
    font-style: normal;
    font-weight: bold;
    margin-left: 70px;
    font-size: 25px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-align: left;
    padding-top: 25px;

    color: #2c4371;
}

.navbar #contents {
    width: inherit;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar #content-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 150px;
}

.navbar-text {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: "Rany", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: capitalize;

    /* line-height: 24px; */
    padding: 0px 15px;
    color: #2c4371;
}

.navbar-link {
    padding-top: 25px;
}

#content-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-icon {
    cursor: pointer;
    display: none;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}

.navbar-icon .navicon {
    background: #ffffff;
    display: block;
    height: 2px;
    position: relative;
    width: 18px;
}

.navbar-icon .navicon:before {
    top: 5px;
}

.navbar-icon .navicon:after {
    top: -5px;
}

.navbar-btn {
    display: none;
}

/* .navbar-btn:checked ~ .navbar {
    max-height: 240px;
  }
  
.navbar-btn:checked ~ .navbar-icon .navicon {
    background: transparent;
  }
  
.navbar-btn:checked ~ .navbar-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
.navbar-btn:checked ~ .navbar-icon .navicon:after {
    transform: rotate(45deg);
  }
  
.navbar-btn:checked ~ .navbar-icon:not(.steps) .navicon:before,
.navbar-btn:checked ~ .navbar-icon:not(.steps) .navicon:after {
    top: 0;
  }

.navbar-icon .navicon:before,
.navbar-icon .navicon:after {
  background: #FFFFFF;
  content: '';
  display: none;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
} */

@media screen and (max-width: 1000px) {
    #navbar {
        width: 10%;
        z-index: 99999;
    }

    .navbar-fixed {
        background-color: transparent;
    }

    #content-left #navbar-title {
        display: none;
    }

    #content-left .navbar-icon {
        display: block;
        height: 20px;
        width: 20px;
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 10003;
        text-align: center;
        cursor: pointer;
    }

    #content-left .navicon,
    #content-left .navicon::before,
    #content-left .navicon::after {
        width: 30px;
        height: 2px;
        background-color: #2c4371;
        display: inline-block;
    }

    #content-left .navicon::before,
    .navicon::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all 0.2s;
    }

    #content-left .navicon::before {
        top: -8px;
    }

    #content-left .navbar-icon:hover .navicon::before {
        top: -10px;
    }

    #content-left .navicon::after {
        top: 8px;
    }

    #content-left .navbar-icon:hover .navicon::after {
        top: 10px;
    }

    .navbar-btn:checked + .navbar-icon .navicon::before {
        top: 0px !important;
        transform: rotate(135deg);
    }

    .navbar-btn:checked + .navbar-icon .navicon {
        background-color: transparent !important;
    }

    .navbar-btn:checked + .navbar-icon .navicon::after {
        top: 0px !important;
        transform: rotate(-135deg);
    }

    .nav-background {
        height: 1px;
        width: 1px;
        border-radius: 50%;
        position: fixed;
        top: 48px;
        left: 53px;
        background-image: radial-gradient(
            at bottom right,
            rgb(235, 199, 68) 0%,
            rgba(251, 239, 231) 82.29%
        );
        transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1),
            -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    }

    .navbar-btn:checked ~ .nav-background {
        transform: scale(5000);
    }

    .navbar-btn:checked #navbar {
        z-index: 10001;
    }

    .navbar #content-right {
        z-index: 10002;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 100vh;
        position: fixed;
        top: 0;
        left: -1000px;
        opacity: 0;
        width: 0;
        -webkit-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .navbar-text {
        font-size: 35px;
        height: 75px;
    }
}
