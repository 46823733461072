/* @import url('http://fonts.cdnfonts.com/css/rany'); */

.speakers {
    width: 100vw;
    height: 100%;
    /* min-height: 100vh; */
    max-width: 100%; /* added */
    display: flex;
    flex-direction: column;
    background: #fbf9f7;
    padding: 75px 150px;
}

@media screen and (max-width: 1024px) {
    .speakers {
        padding: 75px 20px !important;
    }

    .speakers-mobile-tracks {
        margin-top: 700px;
    }
}

.speaker-name {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #d9d9d9;
}

.speaker-name img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.speaker-info-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}

.speaker-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

@media screen and (max-width: 1864px) {
    .speaker-info {
        flex: 1 0 19%;
    }
}

.speaker-info h4 {
    font-family: "Rany";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    color: #26467e;
    padding-top: 20px;
}

.speaker-info p {
    font-family: "Rany";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    color: #26467e;
}

.speakers h2 {
    font-family: "Rany";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: 0.05em;
    padding-bottom: 25px;
    color: #26467e;
}

#speakers-blurb {
    font-family: "Rany";
    font-style: normal;
    font-weight: 300;
    font-size: 22.5px;
    line-height: 30px;
    color: #26467e;
    padding-bottom: 100px;
}

.speaker-name-text {
    text-align: center;
}

.speaker-description {
    max-width: 185px;
    text-align: center;
}
