/* @import url("http://fonts.cdnfonts.com/css/rany"); */
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

.sponsors-logos {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}
.apply-link {
    margin-top: 16%;
}

.sponsors-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    height: 150px;
    width: 60%;
}
.sponsors-row-big {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    height: 150px;
    width: 80%;
}

@media screen and (max-width: 1100px) {
    .sponsors-row {
        width: 90%;
    }
}

@media screen and (max-width: 900px) {
    .sponsors-row {
        height: 120px;
    }

    .sponsors-col {
        margin: 20px !important;
    }
}

@media screen and (max-width: 600px) {
    .sponsors-row {
        height: 100px;
    }
}

.sponsors-img {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.sponsors-col {
    position: relative;
    flex: 1;
    margin: 20px 40px;
}
#sponsors {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(
        180deg,
        rgb(246, 228, 161, 0.2) 33%,
        rgb(217, 224, 132, 0.3) 66%,
        rgb(170, 219, 183, 0.4) 99%
    );
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.sponsors-container {
    display: flex;
    flex-direction: column;
    background: #fbf9f7;
}

.sponsors-container h2 {
    font-family: "Rany";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: 0.05em;
    padding-bottom: 25px;
    color: #26467e;
    padding-left: 150px;
}

@media screen and (max-width: 1024px) {
    .sponsors-container h2 {
        padding-left: 20px;
    }
}
.sponsors-container img {
    margin-bottom: 10;
    padding: 0px;
    width: 100%;
}
#footer_flowers {
    /* margin-top: 200px; */
    width: 100%;
}

@media only screen and (max-width: 1440px) {
    #footer_flower {
        /* width: 100%; */
        margin-top: 0px;
    }
}
